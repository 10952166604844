@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#check-box-1:checked ~ .check-1 {
  @apply text-opacity-100;
}

@font-face {
  font-family: 'Moul';
  src: url('../src/assets/fonts/Moul.ttf');
}

.logo-text {
  font-family: Moul;
}

.disable-scroll {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.1
  ); /* Background color with opacity for dimming */
  backdrop-filter: blur(2px); /* Adjust the blur effect as needed */
  z-index: 999; /* Ensure it's above the content */
}

/* Style the container with the custom scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #9aa0a6 transparent;
  overflow-y: scroll;
}

/* Style the scrollbar track */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Style the scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9aa0a6;
  border-radius: 4px;
}

/* Style the scrollbar thumb when hovered */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8f692c;
}

/* PushNotification.css */
@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.slide-in {
  animation: slide-in 0.5s ease-in-out forwards;
}

.slide-out {
  animation: slide-out 0.5s ease-in-out forwards;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #ffd704;
}

.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #c38d01;
}

.upTrendCartLine {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url('data:image/svg+xml;utf8,%3Csvg height=%22auto%22 viewBox=%220 0 2000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d=%22M0 998.004c30.8-14.283 92.4-49.55 154-71.412 61.6-21.862 92.4-14.137 154-37.9 61.6-23.762 92.4-41.707 154-80.912 61.6-39.205 92.4-98.454 154-115.112 61.6-16.658 92.4 18.848 154 31.82 61.6 12.974 92.4 42.373 154 33.045 61.6-9.327 92.4-62.045 154-79.683 61.6-17.64 92.4 44.358 154-8.51 61.6-52.867 92.4-241.196 154-255.828 61.6-14.632 92.4 175.802 154 182.668 61.6 6.866 92.4-123.888 154-148.336 61.6-24.448 92.4 62.53 154 26.095 61.6-36.437 123.2-166.621 154-208.276L2000 1000H0Z%22 fill=%22%2339b6591a%22%2F%3E%3Cpath d=%22M0 998.004c30.8-14.283 92.4-49.55 154-71.412 61.6-21.862 92.4-14.137 154-37.9 61.6-23.762 92.4-41.707 154-80.912 61.6-39.205 92.4-98.454 154-115.112 61.6-16.658 92.4 18.848 154 31.82 61.6 12.974 92.4 42.373 154 33.045 61.6-9.327 92.4-62.045 154-79.683 61.6-17.64 92.4 44.358 154-8.51 61.6-52.867 92.4-241.196 154-255.828 61.6-14.632 92.4 175.802 154 182.668 61.6 6.866 92.4-123.888 154-148.336 61.6-24.448 92.4 62.53 154 26.095 61.6-36.437 123.2-166.621 154-208.276%22 fill=%22none%22 stroke=%22%2339b659%22 stroke-width=%223%22%2F%3E%3C%2Fsvg%3E');
}

.downTrendCartLine {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url('data:image/svg+xml;utf8,%3Csvg height=%22auto%22 viewBox=%220 0 2000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d=%22M0 29.533c30.8 8.15 92.4 16.537 154 40.746 61.6 24.21 92.4 48.19 154 80.302 61.6 32.113 92.4 67.667 154 80.26 61.6 12.594 92.4-17.164 154-17.293 61.6-.128 92.4-31.802 154 16.652 61.6 48.453 92.4 206.275 154 225.616 61.6 19.34 92.4-100.183 154-128.914 61.6-28.731 92.4-37.186 154-14.741 61.6 22.444 92.4 95.282 154 126.964 61.6 31.683 92.4-18.341 154 31.447 61.6 49.788 92.4 206.13 154 217.493 61.6 11.363 92.4-143.913 154-160.677 61.6-16.765 123.2 61.483 154 76.854L2000 1000H0Z%22 fill=%22%23ff00001a%22%2F%3E%3Cpath d=%22M0 29.533c30.8 8.15 92.4 16.537 154 40.746 61.6 24.21 92.4 48.19 154 80.302 61.6 32.113 92.4 67.667 154 80.26 61.6 12.594 92.4-17.164 154-17.293 61.6-.128 92.4-31.802 154 16.652 61.6 48.453 92.4 206.275 154 225.616 61.6 19.34 92.4-100.183 154-128.914 61.6-28.731 92.4-37.186 154-14.741 61.6 22.444 92.4 95.282 154 126.964 61.6 31.683 92.4-18.341 154 31.447 61.6 49.788 92.4 206.13 154 217.493 61.6 11.363 92.4-143.913 154-160.677 61.6-16.765 123.2 61.483 154 76.854%22 fill=%22none%22 stroke=%22red%22 stroke-width=%223%22%2F%3E%3C%2Fsvg%3E');
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #c38d01;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
